
import Mousetrap from 'mousetrap'
import { clone } from 'lodash'
import { mapStores, mapState, mapActions } from 'pinia'
import { corporaMixin } from '@/mixins'
import { DEFAULT_POLYGON_COLOR, HOVERED_TREE_ITEM_COLOR, ELEMENT_LIST_MAX_AUTO_PAGES } from '@/config'
import {
  useAnnotationStore,
  useElementStore,
  useNotificationStore,
  useTreeStore
} from '@/stores'
import { defineComponent, PropType } from 'vue'
import { Tree, Element, ElementBase, UUID } from '@/types'

/**
 * Represents an element and their direct
 * children on an interactive tree view
 */
export default defineComponent({
  name: 'TreeItem',
  mixins: [
    corporaMixin
  ],
  emits: ['edit', 'transcribe'],
  props: {
    parentId: {
      type: String,
      required: true
    },
    node: {
      // { element: Element, children: node[] }
      type: Object as PropType<Tree>,
      required: true
    },
    interactive: {
      // Allow interactions between elements in the tree and the main element image
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    loading: false
  }),
  mounted () {
    if (this.isParent) Mousetrap.bind('m', () => { this.fetchChildren() })
  },
  unmounted () {
    Mousetrap.unbind('m')
  },
  computed: {
    ...mapStores(useAnnotationStore),
    ...mapState(useElementStore, ['childrenPagination', 'parents']),
    element () {
      return this.node.element || {}
    },
    elementType () {
      return this.element && this.getType(this.element.type)
    },
    hasChildren () {
      return this.isParent || ('has_children' in this.element && this.element.has_children === true)
    },
    isParent () {
      // This node is the main tree node
      return this.element.id === this.parentId
    },
    corpusId () {
      return this.element.corpus?.id
    },
    visibleIds () {
      return this.annotationStore.visible[this.parentId] ?? new Set()
    },
    isVisible () {
      // This node is part of visible elements
      return this.visibleIds.has(this.element.id) && this.interactive
    },
    isHovered () {
      // Node is hovered by the mouse, either from the tree or the interactive image
      return this.annotationStore.hoveredId === this.element.id
    },
    isSelected () {
      // Select the parent element by default e.g. no children is selected or the selected element is not created yet
      if (this.annotationStore.selectedElement === null || this.annotationStore.selectedElement.id === 'created-polygon') return this.isParent
      return this.interactive && this.annotationStore.selectedElement.id === this.element.id
    },
    hoveredStyle () {
      // Router link background is colored when element can be selected from the tree
      if ((!this.isHovered || !this.isVisible) && !this.isSelected) return
      return {
        'background-color': HOVERED_TREE_ITEM_COLOR,
        border: this.isSelected ? 'solid 1px #ddd' : null
      }
    },
    title () {
      const action = this.interactive ? 'Select' : 'Explore'
      const { type, name } = this.element
      return `${action} ${this.typeName(type)} ${name}`
    },
    page () {
      return this.childrenPagination[this.element.id]
    },
    hasNext () {
      // All children have not been loaded
      return Boolean(this.page && this.page.next)
    },
    toggledColor () {
      return `color: ${this.isVisible ? `${DEFAULT_POLYGON_COLOR};` : 'lightgrey;'}`
    }
  },
  methods: {
    ...mapActions(useTreeStore, ['toggleNode']),
    ...mapActions(useElementStore, ['nextChildren']),
    ...mapActions(useNotificationStore, ['notify']),
    async toggle () {
      if (this.loading) return
      this.loading = true
      // Toggle this tree node and fetch children
      try {
        await this.toggleNode(this.element.id)
      } catch (err) {
        const nodeInfo = `${this.element.name} - ${this.element.id}`
        this.notify({ type: 'error', text: `An error occurred fetching children of node "${nodeInfo}".` })
      } finally {
        this.loading = false
      }
    },
    hover () {
      if (this.isHovered) return
      this.annotationStore.hoveredId = this.element.id
    },
    unhover () {
      if (!this.isHovered) return
      this.annotationStore.hoveredId = null
    },
    elementRoute (id: UUID) {
      return { name: 'element-details', params: { id } }
    },
    toggleVisible () {
      this.annotationStore.setVisible(
        this.parentId,
        this.element.id,
        !this.isVisible
      )
    },
    interactiveSelect (element: Element | ElementBase) {
      if (!this.interactive) return
      if (this.annotationStore.enabled && this.annotationStore.tool !== 'select') {
        // In edition mode, set current tool to selection
        this.annotationStore.setTool('select')
      }
      if (
        // The selected element is unselected
        element.id === this.annotationStore.selectedElement?.id ||
        // The parent element is clicked
        element.id === this.parentId
      ) {
        this.annotationStore.selectedElement = null
      } else {
        this.annotationStore.selectedElement = {
          ...element
        }
        if (this.$route.name !== null && this.$route.query.highlight !== element.id) {
          const query = { ...clone(this.$route.query) }
          query.highlight = element.id
          this.$router.replace({ name: this.$route.name, query })
        }
      }
    },
    async fetchChildren (max = ELEMENT_LIST_MAX_AUTO_PAGES) {
      // Fetch remaining children
      if (this.loading) return
      if (this.page && !this.hasNext) return
      this.loading = true
      try {
        await this.nextChildren(this.element.id, true, true, max)
      } catch (err) {
        this.notify({ type: 'error', text: `Error fetching next elements for parent "${this.element.id}"` })
      } finally {
        this.loading = false
      }
    }
  },
  watch: {
    element: {
      immediate: true,
      handler (newValue, oldValue) {
        /*
         * Automatically load all children if this TreeItem is the parent node of the tree.
         * This is done as a watcher and not just in mounted as it is possible that the element changes without
         * unmounting the component if the user switches between neighbors and the element is already in the store,
         * for example when going back and forth between two elements multiple times,
         * or when the element is in the selection or was in a navigation list.
         */
        if (oldValue?.id === newValue.id || !this.isParent) return
        this.fetchChildren()
      }
    },
    'annotationStore.selectedElement': {
      immediate: true,
      handler (newValue, oldValue) {
        /*
         * Open every TreeItem all the way down to a visible element.
         * This uses the fact that the highlighting will load all parents into the `parents` state, and nothing else.
         */
        if (!newValue || (oldValue && newValue === oldValue)) return
        const parents = this.parents[newValue.id]
        if (parents && parents.includes(this.node.element.id) && !this.node.expanded) {
          this.toggle()
          this.fetchChildren()
        }
      }
    }
  }
})
