import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["value", "placeholder"]
const _hoisted_2 = ["value", "placeholder"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.field.multiline)
    ? (_openBlock(), _createElementBlock("textarea", {
        key: 0,
        class: "textarea",
        value: _ctx.modelValue,
        onInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:modelValue', ($event.target as HTMLInputElement).value))),
        placeholder: _ctx.field.type
      }, null, 40, _hoisted_1))
    : (_openBlock(), _createElementBlock("input", {
        key: 1,
        class: "input",
        value: _ctx.modelValue,
        onInput: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('update:modelValue', ($event.target as HTMLInputElement).value))),
        placeholder: _ctx.field.type
      }, null, 40, _hoisted_2))
}