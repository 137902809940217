import { isAxiosError } from 'axios'
import { defineStore } from 'pinia'

import {
  listAgents,
  listArtifacts,
  listFarms,
  restartTask,
  retrieveAgent,
  retrieveTask,
  TaskUpdatePayload,
  updateTask
} from '@/api'

import { TASK_POLLING_DELAY } from '@/config'
import { errorParser } from '@/helpers'

import type { UUID } from '@/types'
import type {
  AgentDetails,
  AgentState,
  Artifact,
  Farm,
  TaskLight
} from '@/types/ponos'

import { useNotificationStore } from '.'

type PollableTask = TaskLight & {
  /**
   * A timeout ID returned by `setTimeout`, used to manage the automatic task polling
   */
  timeoutId?: number
}

interface State {
  agents: Record<UUID, (AgentDetails | AgentState)>
  farms: Record<UUID, Farm> | null
  artifacts: { [artifactId: UUID]: Artifact[] | undefined }
  /**
   * Tasks do not have an explicit process ID attribute,
   * so this stores task IDs for each process.
   */
  processTaskIds: { [processId: UUID]: UUID[] | undefined }
  tasks: { [taskId: UUID]: PollableTask | undefined }
}

export const usePonosStore = defineStore('ponos', {
  state: (): State => ({
    agents: {},
    farms: null,
    artifacts: {},
    tasks: {},
    processTaskIds: {}
  }),
  actions: {
    async retrieveAgent (agentId: UUID) {
      const agent = await retrieveAgent(agentId)
      this.agents[agent.id] = agent
    },

    async listAgents (page = 1) {
      const response = await listAgents({ page })
      this.agents = {
        ...this.agents,
        ...Object.fromEntries(response.results.map(agent => [agent.id, {
          ...(this.agents[agent.id] ?? {}),
          ...agent
        }]))
      }
      if (!response || !response.number || page !== response.number) {
        throw new Error('Pagination failed listing ponos agents')
      }
      if (response.next) await this.listAgents(page + 1)
    },

    async listFarms (page = 1) {
      const response = await listFarms({ page })
      this.farms = {
        ...(this.farms ?? {}),
        ...Object.fromEntries(response.results.map(farm => [farm.id, farm]))
      }
      if (!response || !response.number || page !== response.number) {
        throw new Error('Pagination failed listing ponos farms')
      }
      if (response.next) await this.listFarms(page + 1)
    },

    async listArtifacts (taskId: UUID) {
      try {
        this.artifacts[taskId] = await listArtifacts(taskId)
      } catch (err) {
        useNotificationStore().notify({ type: 'error', text: errorParser(err) })
      }
    },

    /**
     * Save tasks returned by RetrieveProcess in this store's state, and overwrite the list of task IDs for this process.
     * Stops any polling on tasks that do not belong to this process.
     */
    setProcessTasks (processId: UUID, tasks: TaskLight[]) {
      this.processTaskIds[processId] = tasks.map(({ id }) => id)
      // Stop polling on any task that is not part of this process anymore
      for (const id of Object.keys(this.tasks)) {
        if (!this.processTaskIds[processId].includes(id)) this.stopPolling(id)
      }
      for (const task of tasks) {
        // Only overwrite the TaskLight attributes, and preserve any Task attributes if they were retrieved by the task polling
        this.tasks[task.id] = {
          ...(this.tasks[task.id] ?? {}),
          ...task
        }
      }
    },

    async updateTask (id: UUID, payload: TaskUpdatePayload) {
      try {
        // Only overwrite the TaskLight attributes, and preserve any Task attributes if they were retrieved by the task polling
        this.tasks[id] = {
          ...(this.tasks[id] ?? {}),
          ...await updateTask(id, payload)
        }
      } catch (err) {
        useNotificationStore().notify({ type: 'error', text: errorParser(err) })
      }
    },

    async restartTask (taskId: UUID, processId: UUID) {
      const newTask = await restartTask(taskId)
      this.tasks[newTask.id] = newTask
      if (Array.isArray(this.processTaskIds[processId])) this.processTaskIds[processId].push(newTask.id)
    },

    startPolling (id: UUID) {
      if (!this.tasks[id]) throw new Error(`Unknown task ${id}`)
      this.stopPolling(id)

      const poll = async () => {
        const task = this.tasks[id]
        // Polling has been stopped, process has changed or task was deleted
        if (!task?.timeoutId) return

        try {
          const updatedTask = await retrieveTask(id)
          this.tasks[id] = {
            ...this.tasks[id],
            ...updatedTask
          }
        } catch (err) {
          useNotificationStore().notify({ type: 'error', text: `Error while fetching task ${task.slug}: ${errorParser(err)}` })

          // Abort polling on HTTP 4xx
          if (isAxiosError(err) && err.response?.status && err.response.status >= 400 && err.response.status < 500) {
            this.stopPolling(id)
            return
          }
        }

        // Check again, because the polling might have been stopped while we were awaiting the HTTP request.
        if (!this.tasks[id]?.timeoutId) return

        /*
         * Call setTimeout through window.setTimeout so that TypeScript does not get confused with Node's setTimeout,
         * which has a different return type
         * https://stackoverflow.com/q/45802988/5990435
         */
        this.tasks[id].timeoutId = window.setTimeout(poll, TASK_POLLING_DELAY)
      }

      // Make the first call; poll cannot be called directly due to the initial timeout ID check
      this.tasks[id].timeoutId = window.setTimeout(poll, 0)
    },

    stopPolling (id: UUID) {
      if (!this.tasks[id]?.timeoutId) return
      window.clearTimeout(this.tasks[id].timeoutId)
      this.tasks[id].timeoutId = undefined
    },

    stopAllPolling () {
      for (const task of Object.values(this.tasks)) {
        if (task?.timeoutId) this.stopPolling(task.id)
      }
    }
  },
  getters: {
    processTasks () {
      return (processId: UUID): TaskLight[] => this.processTaskIds[processId]?.map(id => this.tasks[id]).filter(task => task !== undefined) ?? []
    }
  }
})
