
import { mapActions, mapState } from 'pinia'
import { useSelectionStore } from '@/stores'
import CorpusSelection from '@/components/Navigation/CorpusSelection.vue'
import { defineComponent } from 'vue'

export default defineComponent({
  components: {
    CorpusSelection
  },
  computed: {
    ...mapState(useSelectionStore, ['count', 'selection']),
    /**
     * Filter corpora to ensure we only display corpora with some selected elements;
     * empty arrays should not matter
     */
    selectedCorpora () {
      return Object.entries(this.selection).filter(([, elements]) => elements?.length)
    }
  },
  methods: {
    ...mapActions(useSelectionStore, ['clear'])
  }
})
