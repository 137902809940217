
import { PropType, defineComponent } from 'vue'

import { METADATA_TYPES, UUID_REGEX } from '@/config'
import { SEARCH_HELP } from '@/help'
import { truncateMixin } from '@/mixins'
import { SearchFacets, SearchFacetsResponse, SearchQuery, SearchSort, SearchSource } from '@/types/search'

import HelpModal from '@/components/HelpModal.vue'
import Facet from './Facet.vue'
import { LocationQuery } from 'vue-router'
import { ensureArray } from '@/helpers'
import { MetaDataType } from '@/types/metadata'

export default defineComponent({
  emits: ['update:modelValue'],
  mixins: [
    truncateMixin
  ],
  components: {
    HelpModal,
    Facet
  },
  props: {
    corpusId: {
      type: String,
      required: true,
      validator: value => typeof value === 'string' && UUID_REGEX.test(value)
    },
    modelValue: {
      type: Object as PropType<LocationQuery>,
      required: true
    },
    allFacets: {
      type: Object as PropType<SearchFacetsResponse>,
      default: () => ({})
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    currentTerms: '',
    sources: {
      element: true,
      transcription: true,
      metadata: true,
      entity: true,
      classification: true
    } as Record<SearchSource, boolean>,
    sort: 'relevance' as SearchSort,
    SEARCH_HELP,
    checkedFacets: {} as Partial<SearchFacets>
  }),
  methods: {
    /**
     * Update `this.checkedFacets` from the facets returned by the API and the filters from the route query.
     */
    updateCheckedFacets (query: LocationQuery, allFacets: SearchFacetsResponse) {
      const facets: Partial<SearchFacets> = {}
      for (const item of Object.keys(allFacets) as (keyof SearchFacetsResponse)[]) {
        // If the facet had a selected value in the URL, set it in this.checkedFacets
        if (item in query) {
          // A single value in a query string is declared by Vue Router to be either null, string, or (string | null)[].
          let value = query[item]
          // First handle the array case…
          if (Array.isArray(value)) value = value[0]
          // Now the value is either a string or null; ignore nulls
          if (value === null) continue
          // Handle metadata types separately because the value is not any string, it should be a MetaDataType
          if (item === 'metadata_type') {
            if (value in METADATA_TYPES) facets[item] = value as MetaDataType
          } else facets[item] = value
        }
      }
      this.checkedFacets = facets
    },
    /**
     * Submit the currently selected query, but remove the `page` parameter from the paginator to restart at the beginning.
     */
    submit () {
      this.$emit('update:modelValue', this.parsedQuery)
    }
  },
  computed: {
    parsedQuery (): SearchQuery {
      return {
        query: this.currentTerms.trim() || '*',
        sources: this.selectedSources,
        sort: this.sort,
        ...this.checkedFacets
      }
    },
    noSource (): boolean {
      return Object.values(this.sources).every(value => value === false)
    },
    selectedSources (): SearchSource[] {
      // Object.entries can only return string keys and not enum types, so we have to use a type assertion
      return (Object.entries(this.sources) as [SearchSource, boolean][]).filter(([, v]) => v).map(([k]) => k)
    }
  },
  watch: {
    allFacets (newValue: SearchFacetsResponse) {
      this.updateCheckedFacets({ ...this.checkedFacets, ...this.modelValue }, newValue)
    },
    /**
     * When switching to another project, reset the stored checked facets,
     * sources and sort option since they are being reset in the route query.
     */
    corpusId () {
      this.checkedFacets = {}
      for (const source of Object.keys(this.sources)) this.sources[source as SearchSource] = true
      this.sort = 'relevance'
    },
    // Parse from URL again if the URL params change
    modelValue: {
      handler ({ query, sources, ...facets }: LocationQuery) {
        // Parse URL params
        this.currentTerms = (Array.isArray(query) ? query[0] : query)?.trim() ?? '*'

        if (sources) {
          const newSources = Object.fromEntries(Object.keys(this.sources).map(key => ([key, false]))) as Record<SearchSource, boolean>
          // When there is only one source in the URL, the parameter might be a single string and not an array
          for (const source of ensureArray(this.modelValue.sources)) {
            if (source && source in this.sources) newSources[source as SearchSource] = true
          }
          this.sources = newSources
        }
        this.updateCheckedFacets(facets, this.allFacets)

        if (this.$route.query.sort && !Array.isArray(this.$route.query.sort)) {
          this.sort = this.$route.query.sort as SearchSort
        }
      },
      immediate: true
    }
  }
})
