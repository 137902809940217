<template>
  <tr :class="{ 'has-background-white-ter': disabled }">
    <td>
      <p>
        <router-link v-if="!disabled" :to="elementRoute" :title="element.name">
          {{ truncateLong(element.name) }}
        </router-link>
        <template v-else :title="element.name">
          {{ truncateLong(element.name) }}
        </template>
      </p>
    </td>
    <td :title="elementTypeName">
      {{ truncateShort(elementTypeName) }}
    </td>
    <td v-if="classDisplay">
      {{ classDisplay }}
    </td>
    <td v-if="!disabled">
      <span class="field has-addons action-buttons">
        <button
          class="button"
          :class="{ 'is-success': isLoggedOn && selected }"
          v-if="hasFeature('selection')"
          v-on:click="toggleSelection"
          :disabled="!isLoggedOn || null"
        >
          <i class="icon-check"></i>
        </button>
        <template v-if="hasPreview">
          <PreviewDropdown :element-id="element.id" />
        </template>
        <DeleteModal :target="element">
          <template v-slot:default="{ open, canDelete }">
            <button
              :class="canDelete ? 'has-text-danger' : 'has-text-grey'"
              :title="canDelete ? 'Delete this element' : 'You do not have the required rights to delete this element'"
              class="button"
              v-on:click="open"
            >
              <i class="icon-trash"></i>
            </button>
          </template>
        </DeleteModal>
      </span>
    </td>
  </tr>
</template>

<script>
import { mapState, mapActions } from 'pinia'
import { mapGetters as mapVuexGetters } from 'vuex'

import { truncateMixin, corporaMixin } from '@/mixins'
import { useDisplayStore, useClassificationStore, useSelectionStore } from '@/stores'

import DeleteModal from '@/components/SingleDeletionModal.vue'
import PreviewDropdown from './PreviewDropdown'

export default {
  mixins: [
    truncateMixin,
    corporaMixin
  ],
  components: {
    DeleteModal,
    PreviewDropdown
  },
  props: {
    element: {
      type: Object,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState(useSelectionStore, ['selection']),
    ...mapState(useDisplayStore, ['elementsTableLayout', 'displayElementClasses']),
    ...mapState(useClassificationStore, ['classifications']),
    ...mapVuexGetters('auth', ['isLoggedOn', 'hasFeature']),
    elementRoute () {
      const query = (this.elementsTableLayout ? { display: 'table' } : {})
      if (this.$route?.params?.id) query.from = this.$route.params.id
      return { name: 'element-details', params: { id: this.element.id }, query }
    },
    corpusId () {
      if (!this.element.corpus) return null
      return this.element.corpus.id
    },
    elementType () {
      if (this.element.type) return this.getType(this.element.type)
      else if (this.element.type_id && this.corpus?.types) return Object.values(this.corpus.types).find(({ id }) => id === this.element.type_id)
      return null
    },
    elementTypeName () {
      return this.elementType?.display_name ?? this.elementType?.slug ?? this.element.type ?? ''
    },
    selected () {
      return this.corpusId !== null && this.selection[this.corpusId]?.includes(this.element.id)
    },
    classDisplay () {
      if (!this.displayElementClasses || !Array.isArray(this.classifications[this.element.id])) return null
      if (!this.classifications[this.element.id].length) return '—'
      const names = this.classifications[this.element.id].map(({ ml_class: { name } }) => name)
      names.sort()
      return names.join(', ')
    },
    /**
     * The backend generates thumbnail URLs for all folder elements, or for non-folder elements with a zone.
     * Since most folder elements do not have a zone anyway, we only display the preview modal for non-folder
     * elements with a thumbnail URL.
     */
    hasPreview () {
      return !this.elementType?.folder && this.element.thumbnail_url
    }
  },
  methods: {
    ...mapActions(useSelectionStore, ['select', 'unselect']),
    toggleSelection () {
      if (this.element.corpus === null) return
      // This pleases TypeScript by making it understand that the corpus is not null on this element
      const element = { ...this.element, corpus: { ...this.element.corpus } }
      if (this.selected) this.unselect(element)
      else this.select([element])
    }
  }
}
</script>

<style scoped>
.has-tiny-text {
  font-size: x-small !important;
  line-height: .5em;
}
.nowrap {
  white-space: nowrap;
}
td {
  vertical-align: middle;
  padding: .25em .75em;
}

.action-buttons > :not(:first-child) {
  margin-left: .5rem;
}
</style>
