<template>
  <main class="container is-fluid" v-if="!element && elementError">
    <div class="notification is-danger">
      {{ elementError }}
    </div>
  </main>
  <div v-else-if="element">
    <ElementHeader v-if="withHeader" :element="element" />
    <main class="container is-fluid">
      <div class="columns is-fullwidth">
        <ChildrenTree
          class="column is-one-fifth"
          :element="element"
          :interactive="isInteractive"
          v-if="showTree"
        />
        <div class="column" :class="displayDetails ? 'is-expanded' : ''">
          <!-- The element is a folder -->
          <ElementNavigation
            v-if="elementType?.folder && corpusId"
            :corpus-id="corpusId"
            :element-id="id"
            v-model:query="query"
          />

          <!-- The element is not a folder and has an image -->
          <template v-else-if="hasImage">
            <InteractiveImage
              :element="element"
              class="fill-height"
            />
            <figcaption>
              <a :href="sourceImageUri" target="_blank">
                View source image
              </a>
              <router-link
                :to="{ name: 'image-elements', params: { imageId: element.zone.image.id } }"
                v-if="isVerified"
                target="_blank"
              >
                List elements on this image
              </router-link>
            </figcaption>
          </template>

          <!-- The element is defined by its children zones (e.g. an act) -->
          <div v-else-if="displayableChildren.length" class="columns is-desktop is-centered">
            <div class="column is-three-quarters-fullhd">
              <ChildElement v-for="elt in displayableChildren" :key="elt.id" :element="elt" />
            </div>
          </div>

          <div class="notification is-warning" v-else>
            No zones for this element
          </div>
        </div>

        <transition name="sidebar" v-if="elementDetailsId">
          <div class="column is-one-third" v-if="displayDetails">
            <PanelHeader :element-id="elementDetailsId" />
            <hr />
            <AnnotationPanel
              :element-id="elementDetailsId"
              v-if="hasImage && annotationStore.enabled"
            />
            <DetailsPanel
              :element-id="elementDetailsId"
              v-else
            />
          </div>
        </transition>
      </div>
    </main>
  </div>
  <main class="container is-fluid" v-else>
    <p>Loading…</p>
  </main>
</template>

<script>
import { mapState, mapActions, mapStores, mapWritableState } from 'pinia'
import {
  mapState as mapVuexState,
  mapGetters as mapVuexGetters
} from 'vuex'

import { errorParser, iiifUri } from '@/helpers'
import { truncateMixin, corporaMixin } from '@/mixins'
import {
  useDisplayStore,
  useClassificationStore,
  useCorporaStore,
  useAnnotationStore,
  useElementStore,
  useTreeStore
} from '@/stores'

import ChildElement from '@/components/Element/ChildElement'
import ElementNavigation from '@/components/Navigation/ElementNavigation'
import ElementHeader from '@/components/Element/ElementHeader'
import DetailsPanel from '@/components/Element/DetailsPanel'
import AnnotationPanel from '@/components/Element/AnnotationPanel'
import ChildrenTree from '@/components/Navigation/ChildrenTree'
import InteractiveImage from '@/components/Image/InteractiveImage'
import PanelHeader from '@/components/Element/PanelHeader'

export default {
  mixins: [
    truncateMixin,
    corporaMixin
  ],
  props: {
    id: {
      type: String,
      required: true
    },
    withHeader: {
      type: Boolean,
      default: true
    }
  },
  components: {
    ElementNavigation,
    ElementHeader,
    DetailsPanel,
    AnnotationPanel,
    ChildrenTree,
    InteractiveImage,
    ChildElement,
    PanelHeader
  },
  data: () => ({
    loadingParents: true,
    elementError: null
  }),
  async created () {
    try {
      await this.load(this.id)
    } finally {
      this.loadingParents = false
    }
  },
  beforeRouteUpdate (to) {
    // Reset the tree type filter except when browsing neighbors
    if (to.name !== 'element-details' || !this.isNeighbor(to.params.id)) this.typeFilter = null
    this.annotationStore.hoveredId = null
  },
  beforeRouteLeave () {
    this.typeFilter = null
  },
  computed: {
    ...mapVuexState('auth', ['user']),
    ...mapVuexGetters('auth', ['isLoggedOn', 'isVerified']),
    ...mapState(useElementStore, ['elements', 'links', 'parents', 'neighbors']),
    ...mapState(useClassificationStore, ['hasMLClasses']),
    ...mapState(useDisplayStore, ['displayDetails', 'displayFolderTree', 'displayAnnotationsTree']),
    ...mapWritableState(useTreeStore, ['typeFilter']),
    ...mapStores(useAnnotationStore, useElementStore),
    element () {
      return this.elements[this.id]
    },
    /**
     * The corporaMixin requires a corpusId property to be defined to be able to retrieve the current corpus.
     * It should either return a corpus ID, or null is there is no corpus.
     * Returning `undefined` can cause a warning in the console, as the mixin thinks the property may not be defined.
     * @returns {string | null}
     */
    corpusId () {
      return this.element?.corpus?.id ?? null
    },
    elementType () {
      return this.element && this.getType(this.element.type)
    },
    displayableChildren () {
      /*
       * Returns non-folder children with zones that should be displayed
       * when the current element is not a folder and has no zone
       * Those children are already loaded by the children tree.
       */
      if (!this.elementType || this.elementType.folder || this.hasImage) return []
      const elementPath = this.links[this.element.id]
      const childrenIds = (elementPath && [...elementPath.children]) || []
      return childrenIds
        .map(childId => this.elements[childId])
        .filter(child => child.zone !== null && !this.getType(child.type).folder)
    },
    hasImage () {
      return Boolean(this.element?.zone)
    },
    sourceImageUri () {
      if (!this.hasImage) return
      return iiifUri({ ...this.element.zone, polygon: null })
    },
    elementDetailsId () {
      if (this.loadingParents && this.highlight) return null
      // Return the ID of an instantiated selected element, main element otherwise
      const selectedId = this.annotationStore.selectedElement?.id
      // Defaults to the main element
      if (!selectedId || selectedId === 'created-polygon') return this.element.id
      return selectedId
    },
    showTree () {
      if (!this.elementType || this.elementType.folder === undefined) return
      return (!this.elementType.folder && this.displayAnnotationsTree) || (this.elementType.folder && this.displayFolderTree)
    },
    highlight () {
      return this.$route.query.highlight
    },
    isInteractive () {
      return !this.elementType?.folder && this.hasImage
    },
    query: {
      get () {
        return this.$route.query
      },
      set (query) {
        this.$router.push({ ...this.$route, query })
      }
    }
  },
  methods: {
    ...mapActions(useClassificationStore, ['listCorpusMLClasses']),
    ...mapActions(useCorporaStore, ['get']),
    async retrieveMLClasses () {
      if (!this.element) return
      /*
       * The ML class creation input does lazy requests to fetch ML classes,
       * but we still want to hide this input when there are zero ML classes available.
       * When an element is loaded, we call this.retrieveMLClasses() on this component
       * which ensures useClassificationStore().hasMLClasses is set to show or hide the input.
       */
      if (this.hasMLClasses[this.corpusId] === undefined) {
        this.listCorpusMLClasses(this.element.corpus.id, { page: 1 })
      }
    },
    async load (id) {
      try {
        await this.elementStore.get(id)
      } catch (e) {
        this.elementError = errorParser(e)
        return
      }
      if (!this.element) return
      await this.get(this.element.corpus.id)
      await this.retrieveMLClasses()
      if (!this.isInteractive || !this.highlight) return
      if (!this.parents[this.highlight]) await this.elementStore.getParentsBulk(this.highlight, this.corpus)
      if (!this.parents[this.highlight] || !this.parents[this.highlight].includes(this.element.id)) return
      if (!this.elements[this.highlight]) await this.elementStore.get(this.highlight)
      const eltPolygon = this.elements[this.highlight]?.zone?.polygon || []
      this.annotationStore.selectedElement = {
        id: this.highlight,
        name: this.elements[this.highlight].name,
        zone: { polygon: [...eltPolygon] }
      }
    },
    isNeighbor (id) {
      return id === this.id || (this.neighbors[this.id]?.flatMap(({ previous, next }) => [previous?.id, next?.id]).includes(id) ?? false)
    }
  },
  watch: {
    async id (newValue, oldValue) {
      if (oldValue !== newValue) {
        this.annotationStore.selectedElement = null
        this.loadingParents = true
        try {
          await this.load(newValue)
        } finally {
          this.loadingParents = false
        }
      }
    }
  }
}
</script>

<style scoped>
.columns.is-fullwidth {
  width: 100%;
  margin-left: 0;
}
.sidebar-enter-active {
  transition: all .4s;
}
.sidebar-leave-active {
  transition: all .4s;
}
.sidebar-enter-from, .sidebar-leave-to {
  transform: translateX(200px);
  opacity: 0;
}
.fill-height {
  /* Fill vertical space available under the headers */
  display: inline-block;
  height: calc(100vh - 11.5rem);
  width: 100%;
}
figcaption > a:not(:first-child) {
  margin-left: 1rem;
}
main {
  margin-top: 1em;
}
</style>
